const CAPTCHA_SITE_KEY = '6LeG-BIqAAAAACwkFFgA5e7F5pYPTR-sAS8qlR2G';

const CAPTCHA_ACTION_TYPE_LOGIN = 'login';
const CAPTCHA_ACTION_TYPE_LOGIN_PASS_RESET = 'loginPassReset';
const CAPTCHA_ACTION_TYPE_REGISTRATION = 'registration';

export default {
  data() {
    return {
      captchaData: {
        sitekey: CAPTCHA_SITE_KEY,
        token: '',
        actionMap: {
          1: CAPTCHA_ACTION_TYPE_LOGIN,
          2: CAPTCHA_ACTION_TYPE_LOGIN_PASS_RESET,
          3: CAPTCHA_ACTION_TYPE_REGISTRATION,
        },
        action: '',
      },
    };
  },
  computed: {
    captchaTypes() {
      return Object.values(this.captchaData.actionMap);
    },
  },
  methods: {
    setCaptchaAction(action) {
      if (this.captchaTypes.indexOf(this.captchaData.actionMap[action]) === -1) {
        throw new Error(`Not defined captcha action type '${action}'`);
      }

      this.$set(this.captchaData, 'action', this.captchaData.actionMap[action]);
      this.processCaptcha();
    },
    doPostCaptchaAction(recaptchaToken) {
      switch (this.captchaData.action) {
        case CAPTCHA_ACTION_TYPE_LOGIN:
          this.login(recaptchaToken);
          break;

        case CAPTCHA_ACTION_TYPE_LOGIN_PASS_RESET:
          this.resetPass(recaptchaToken);
          break;

        case CAPTCHA_ACTION_TYPE_REGISTRATION:
          this.registration(recaptchaToken);
          break;
      }
    },
    captchaErrorNotify() {
      this.$message.errorMessage('Ошибка обновления капчи');
    },
    errorCaptcha(error) {
      this.captchaErrorNotify();
      throw new Error(`Captcha error: ${error}`);
    },
    processCaptcha() {
      try {
        this.$refs.recaptcha.execute();
      } catch (e) {
        console.error(e, 'processCaptcha');
        this.captchaErrorNotify();
      }
    },
    onCaptchaExpired() {
      try {
        this.resetCaptcha();
      } catch (e) {
        console.error(e, 'onCaptchaExpired');
        this.captchaErrorNotify();
      }
    },
    resetCaptcha() {
      try {
        this.$refs.recaptcha.reset();
      } catch (e) {
        console.error(e, 'resetCaptcha');
        this.captchaErrorNotify();
      }
    },
  },
};
