<template>
    <div class="access__container">
        <div class="access__header">
            Нет доступа. У вас есть вопросы?
        </div>
        <div class="access__content">
            <div class="access__content--row">
                <label class="access__content--label">
                    Email
                </label>

                <input v-model="help.email" class="access__content--input" type="email" />
            </div>
            <div class="access__content--row">
                <label class="access__content--label">
                    Проблема
                </label>

                <input v-model="help.title" class="access__content--input" />
            </div>
            <div class="access__content--row -big">
                <label class="access__content--label">
                    Сообщение
                </label>

                <textarea v-model="help.message" class="access__content--input -big"></textarea>
            </div>
        </div>
        <div class="access__button">
            <v-button
                text="Отправить"
                type="green"
                width="174"
                height="40"
                @button-click="sendHelp"
            />
        </div>
    </div>
</template>

<script>
import Buttons from '../helpers/Buttons.vue';
import help from '../../mixins/help';

export default {
  name: 'HasNotAccess',
  components: {
    'v-button': Buttons,
  },
  mixins: [
    help
  ],
  data() {
    return {
      help: {
        email: '',
        title: '',
        message: '',
      },
    };
  },
  deactivated() {
    this.$set(this.help, 'email', '');
    this.$set(this.help, 'title', '');
    this.$set(this.help, 'message', '');
  },
  methods: {
    sendHelp() {
      this.sendHelpAsync(this.help.email, this.help.title, this.help.message)
        .then((data) => {
          if (data) {
            this.$set(this.help, 'title', '');
            this.$set(this.help, 'message', '');
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.access {
  &__container {
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
    overflow: auto;
    padding: 40px;

    display: flex;
    flex-direction: column;
  }

  &__header {
    width: 100%;
    height: 50px;
    min-width: 50px;
    font-family: Circe;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 35px;
    color: #000000;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;

    &--row {
      height: 60px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      max-width: 900px;

      &.-big {
        height: 330px;
        align-items: center;
      }
    }

    &--label {
      min-width: 100px;
      font-family: Circe;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 25px;
      color: #000000;
    }

    &--input {
      width: 100%;
      border: 1px solid #F1F1F1;
      box-sizing: border-box;
      border-radius: 2px;
      padding: 7px;
      font-family: Circe;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 25px;
      color: #212121;
      height: 40px;

      &.-big {
        height: 300px;
      }
    }
  }

  &__button {
    display: flex;
    height: 40px;
    min-height: 40px;
    margin-top: 20px;
    width: 100%;
  }
}

  @media screen and (max-width: 520px){
    .access__header {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
      height: 100px;
    }

    .access__content--row {
      margin-bottom: 20px;
      min-height: 80px;
      flex-direction: column;
    }
    .access__content--label {
      width: 100%;
      min-height: 29px;
      display: flex;
      justify-content: center;
      margin-bottom: 8px;
    }

    .access__button {
      justify-content: center;
      margin-top: 10px;
    }
  }
</style>
