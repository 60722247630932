<template>
    <div class="simple-registration-container">
        <div class="simple-registration--grid">
            <div class="simple-registration__element simple-registration__element--top"></div>
            <div class="simple-registration__element simple-registration__element--header">
                <span class="simple-registration__text simple-registration__text--header">Регистрация</span>
            </div>
            <div class="simple-registration__element simple-registration__element--inputs">

                <!--          EMAIL-->
                <div class="row simple-registration__text simple-registration__text--sub-header">
                    <label for="email">
                        Email
                    </label>
                </div>
                <div class="row">
                    <input
                        id="email" v-model="formData.email"
                        disabled
                        class="col-12 simple-registration__input"
                        :class="{'errors -input': emailError.length > 0}"
                        placeholder="example@gmail.com"
                        type="text"
                    />
                </div>

                <!--          PASSWORD-->
                <div class="row simple-registration__text simple-registration__text--sub-header mt-2">
                    <label for="password">
                        Пароль
                    </label>
                </div>
                <div class="row">
                    <input
                        id="password" v-model="formData.password"
                        class="col-12 simple-registration__input"
                        :class="{'errors -input': passError.length > 0}"
                        type="password"
                    />
                </div>

                <!--          PASSWORD CHECK-->
                <div class="row simple-registration__text simple-registration__text--sub-header mt-2">
                    <label for="password_check">
                        Повторите пароль
                    </label>
                </div>
                <div class="row">
                    <input
                        id="password_check" v-model="formData.passwordDuplicate"
                        class="col-12 simple-registration__input"
                        :class="{'errors -input': corruptedPass}"
                        type="password"
                    />
                </div>

                <!--          NAME-->
                <div class="row simple-registration__text simple-registration__text--sub-header mt-2">
                    <label for="name">
                        ФИО
                    </label>
                </div>
                <div class="row">
                    <input
                        id="name" v-model="formData.name"
                        class="col-12 simple-registration__input"
                        type="text"
                    />
                </div>

                <!--          PHONE -->
                <div class="row simple-registration__text simple-registration__text--sub-header mt-2">
                    <label for="phone">
                        Телефон
                    </label>
                </div>
                <div class="row">
                    <input
                        id="phone" v-model="formData.phone"
                        class="col-12 simple-registration__input"
                        type="text"
                    />
                </div>

                <!--          COMPANY NAME -->
                <div class="row simple-registration__text simple-registration__text--sub-header mt-2">
                    <label for="companyName">
                        Название компании
                    </label>
                </div>
                <div class="row">
                    <input
                        id="companyName" v-model="formData.companyName"
                        class="col-12 simple-registration__input"
                        type="text"
                    />
                </div>
            </div>

            <!--          BUTTON REGISTRATION-->
            <div class="simple-registration__element simple-registration__element--buttons">
                <div
                    class="simple-registration__button simple-registration__button--flex-center"
                    @click="setCaptchaAction(3)"
                >
                    <span class="simple-registration__text simple-registration__text--buttons">
                        Регистрация
                    </span>
                </div>
            </div>

            <!--          ERRORS-->
            <div v-if="formError" class="simple-registration__element simple-registration__element--errors">
                <div v-if="passError.length > 0" class="errors -text">
                    {{ passError }}
                </div>

                <div v-if="emailError.length > 0" class="errors -text">
                    {{ emailError }}
                </div>

                <div v-if="corruptedPass" class="errors -text">
                    Пароли не совпадают
                </div>
            </div>

            <!--          FOOTER-->
            <div class="simple-registration__element simple-registration__element--footer">
                <img src="../../img/bio.png" alt="Agro Bio Tech" width="195" />
            </div>
        </div>

        <vue-recaptcha
            ref="recaptcha"
            size="invisible"
            :sitekey="captchaData.sitekey"
            @verify="doPostCaptchaAction"
            @error="errorCaptcha"
            @expired="onCaptchaExpired"
        />
    </div>
</template>

<script>
  import '../../sass/pages/simple/registration.scss';
  import captcha from '../../mixins/captcha';
  import VueRecaptcha from 'vue-recaptcha';

export default {
  name: 'Registration',
  components: {
    VueRecaptcha,
  },
  mixins: [
    captcha
  ],
  props: {},
  data() {
    return {
      updateToken: root_element.dataset.updateToken,
      formError: false,
      emailError: '',
      passError: '',
      nameError: '',
      phoneError: '',
      companyError: '',
      corruptedPass: false,
      formData: {
        checker: false,
        email: root_element.dataset.email,
        password: '',
        passwordDuplicate: '',
        name: '',
        phone: '',
        companyName: '',
      },
    };
  },
  watch: {
    formData: {
      deep: true,
      handler: function () {
        this.formError = false;
        this.corruptedPass = false;

        this.clearErrors();
      },
    },
  },
  mounted() {
    this.resetCaptcha();
  },
  methods: {
    clearErrors() {
      this.formError = false;

      this.passError = '';
      this.emailError = '';
      this.nameError = '';
      this.phoneError = '';
      this.companyError = '';
    },
    resetPass() {
        //
    },
    checkErrors() {
      const corruptedPassFlag = this.formData.password !== this.formData.passwordDuplicate;

      this.formError = corruptedPassFlag;
      this.corruptedPass = corruptedPassFlag;

      this.clearErrors();

      return corruptedPassFlag;
    },
    registration(recaptchaToken) {
      if (this.checkErrors()) {
        this.$message.errorMessage('Пароли не совпадают');
        return;
      }

      const registrationFormData = new FormData();
      registrationFormData.append('username', this.formData.name);
      registrationFormData.append('email', this.formData.email);
      registrationFormData.append('password', this.formData.password);
      registrationFormData.append('phone', this.formData.phone);
      registrationFormData.append('update_token', this.updateToken);
      registrationFormData.append('company_name', this.formData.companyName);
      registrationFormData.append('recaptchaToken', recaptchaToken);

      this.$_API.post(this.$_API_ROUTES.auth.registration, registrationFormData).then(({data}) => {
        this.resetCaptcha();

        if (data.errors) {
          this.$errors.parseErrors(data.errors);
          this.formError = true;

          // TODO: must be refactored!
          if (_.has(data.errors, 'email.0')) {
            this.emailError = data.errors.email[0];
          }

          if (_.has(data.errors, 'password.0')) {
            this.passError = data.errors.password[0];
          }

          if (_.has(data.errors, 'phone.0')) {
            this.phoneError = data.errors.phone[0];
          }

          if (_.has(data.errors, 'company_name.0')) {
            this.companyError = data.errors.company_name[0];
          }

          if (_.has(data.errors, 'username.0')) {
            this.nameError = data.errors.username[0];
          }
        }

        if (data.success && data.token) {
          this.$_Auth.setAuthToken(data.token);
          this.$_API_HELPER.redirectToAdmin();
        }
      }).catch((error) => {
        console.error(error);
      });
    },
  },
};
</script>
