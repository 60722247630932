<template>
    <div class="simple-container">
        <div class="simple--grid">
            <div class="simple__element simple__element--top"></div>
            <div class="simple__element simple__element--header">
                <span class="simple__text simple__text--header">Вход</span>
            </div>
            <div class="simple__element simple__element--inputs">
                <div class="row simple__text simple__text--sub-header">
                    <label for="email">
                        Email
                    </label>
                </div>
                <div class="row">
                    <input
                        id="email" v-model="formData.email"
                        :class="{'errors -input': formError}"
                        class="col-12 simple__input"
                        placeholder="example@gmail.com"
                        type="text"
                    />
                </div>

                <div class="row simple__text simple__text--sub-header mt-2">
                    <label for="password">
                        Пароль
                    </label>
                </div>
                <div class="row">
                    <input
                        id="password" v-model="formData.password"
                        :class="{'errors -input': passwordError.length}"
                        class="col-12 simple__input"
                        type="password"
                    />
                </div>
                <div class="row simple__text--flex mt-2">
                    <div class="simple__text simple__text--remember">
                        <input id="checkbox" v-model="formData.checker" type="checkbox" />
                        <label for="checkbox" class="simple__text simple__text--remember">
                            Запомнить меня
                        </label>
                    </div>
                    <div class="simple__text simple__text--additional" @click="setCaptchaAction(2)">
                        Забыли пароль?
                    </div>
                </div>
            </div>
            <div class="simple__element simple__element--buttons">
                <div class="simple__button simple__button--flex-center" @click="setCaptchaAction(1)">
                    <span class="simple__text simple__text--buttons">
                        Войти
                    </span>
                </div>
            </div>

            <!--          FORM ERRORS BLOCK-->
            <div v-if="formError" class="simple__element simple__element--errors">
                <div v-if="passwordError.length > 0" class="errors -text">
                    {{ passwordError }}
                </div>

                <div v-if="emailError.length > 0" class="errors -text">
                    {{ emailError }}
                </div>
            </div>
            <!--          FORM ERRORS BLOCK END-->

            <div class="simple__element simple__element--footer">
                <img src="../../img/bio.png" alt="Agro Bio Tech" width="195" />
            </div>
        </div>
        <create-modal v-if="modalState" :email="formData.email" @close="handleClose" />

        <vue-recaptcha
            ref="recaptcha"
            size="invisible"
            :sitekey="captchaData.sitekey"
            @verify="doPostCaptchaAction"
            @error="errorCaptcha"
            @expired="onCaptchaExpired"
        />
    </div>
</template>

<script>
  /**
   * Styles import
   */
   import '../../sass/pages/simple/simple.scss';
   import '../../sass/helpers/modal.scss';
  import VueRecaptcha from 'vue-recaptcha';

  /*
   * Components import
   */
  import ModalPassReset from '../modals/ModalPassReset.vue';
  import captcha from '../../mixins/captcha';

export default {
  name: 'Login',
  components: {
    'create-modal': ModalPassReset,
    VueRecaptcha,
  },
  mixins: [
    captcha
  ],
  data() {
    return {
      modalState: false,
      formError: false,
      passwordError: '',
      emailError: '',
      formData: {
        checker: true,
        email: '',
        password: '',
      },
    };
  },
  watch: {
    formData: {
      deep: true,
      handler: function () {
        this.clearErrors();
      },
    },
  },
  methods: {
    clearErrors() {
      this.formError = false;
      this.passwordError = '';
      this.emailError = '';
    },
    handleClose() {
      this.modalState = false;
    },
    resetPass(recaptchaToken) {
      this.clearErrors();

      const passRestoreData = new FormData();
      passRestoreData.append('email', this.formData.email);
      passRestoreData.append('recaptchaToken', recaptchaToken);

      this.$_API.post(this.$_API_ROUTES.auth.passRestore, passRestoreData)
        .then(({data}) => {
          this.resetCaptcha();

          if (data.errors) {
            this.$errors.parseErrors(data.errors);
            this.formError = true;

            if (_.has(data.errors, 'email.0')) {
              this.emailError = data.errors.email[0];
            }
          }

          if (data.success) {
            this.modalState = true;
          }
        });
    },
    login(recaptchaToken) {
      try {
        this.clearErrors();

        const loginForm = new FormData();
        loginForm.append('email', this.formData.email);
        loginForm.append('password', this.formData.password);
        loginForm.append('rememberMe', this.formData.checker);
        loginForm.append('recaptchaToken', recaptchaToken);

        this.$_API.post(this.$_API_ROUTES.auth.login, loginForm).then(({data}) => {
          this.resetCaptcha();

          if (data.success) {
            if (_.has(data, 'token')) {
              this.$_Auth.setAuthToken(data.token, this.formData.checker);
              if (data.role === 'agronomist' || data.role === 'monitor') {
                this.$_API_HELPER.redirectToAgronom();
                return;
              }

              this.$_API_HELPER.redirectToAdmin();
            } else {
              console.error('Auth failed on server error!');
            }
          }

          // TODO: move to mixin
          if (!data.success && data.errors) {
            this.$errors.parseErrors(data.errors);

            this.resetCaptcha();
            this.formError = true;

            if (_.has(data.errors, 'email.0')) {
              this.emailError = data.errors.email[0];
            }

            if (_.has(data.errors, 'password.0')) {
              this.passwordError = data.errors.password[0];
            }

            if (_.has(data.errors, 'Permission')) {
              this.$_API_HELPER.redirectToAccess();
            }
          }
        });
      } catch (error) {
        this.$message.errorMessage(error.toString());
      }
    },
  },
};
</script>

<style scoped>
</style>
