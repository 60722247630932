<template>
    <div class="simple-container">
        <div class="simple--grid">
            <div class="simple__element simple__element--top"></div>
            <div class="simple__element simple__element--header">
                <span class="simple__text simple__text--header">Создание пароля</span>
            </div>
            <div class="simple__element simple__element--inputs">
                <div class="row simple__text simple__text--sub-header">
                    <label for="email">
                        Email
                    </label>
                </div>
                <div class="row">
                    <input
                        id="email" v-model="formData.email"
                        class="col-12 simple__input"
                        placeholder="example@gmail.com"
                        type="text"
                    />
                </div>

                <div class="row simple__text simple__text--sub-header mt-2">
                    <label for="password">
                        Пароль
                    </label>
                </div>
                <div class="row">
                    <input
                        id="password" v-model="formData.password"
                        class="col-12 simple__input"
                        type="password"
                    />
                </div>
                <div class="row simple__text--flex mt-2">
                    <div class="simple__text simple__text--remember">
                        <input id="checkbox" v-model="formData.checker" type="checkbox" />
                        <label for="checkbox" class="simple__text simple__text--remember">
                            Запомнить меня
                        </label>
                    </div>
                    <div class="simple__text simple__text--additional" @click="resetPass">
                        Забыли пароль?
                    </div>
                </div>
            </div>
            <div class="simple__element simple__element--buttons">
                <div class="simple__button simple__button--flex-center" @click="login">
                    <span class="simple__text simple__text--buttons">
                        Войти
                    </span>
                </div>
            </div>
            <div class="simple__element simple__element--footer">
                <img src="../../img/bio.png" alt="Agro Bio Tech" width="195" />
            </div>
        </div>
    </div>
</template>

<script>
  import '../../sass/pages/simple/simple.scss';

export default {
  name: 'NewPass',
  data() {
    return {
      formData: {
        checker: true,
        email: '',
        password: '',
      },
    };
  },
  methods: {
    changeLocation() {
      window.location.href = '/site/admin';
    },
    resetPass() {
        //
    },
    login() {
      this.changeLocation();

      const loginRoute = 'user/login';
      this.$_API.post(loginRoute, this.formData).then(({data}) => {
        if (data.success) {
          console.warn('OK');
        }
      });
    },
  },
};
</script>

<style scoped>

</style>
