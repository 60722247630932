<template>
    <div class="simple-container">
        <div class="simple--grid">
            <div class="simple__element simple__element--top"></div>
            <div class="simple__element simple__element--header">
                <span class="simple__text simple__text--header">Создание пароля</span>
            </div>
            <div class="simple__element simple__element--inputs">
                <div class="row simple__text simple__text--sub-header">
                    <label for="password">
                        Пароль
                    </label>
                </div>
                <div class="row">
                    <input
                        id="password" v-model="formData.password"
                        :class="{'errors -input': formError}"
                        class="col-12 simple__input"
                        type="password"
                    />
                </div>

                <div class="row simple__text simple__text--sub-header mt-2">
                    <label for="passwordDuplicate">
                        Повторите пароль
                    </label>
                </div>
                <div class="row">
                    <input
                        id="passwordDuplicate" v-model="formData.passwordDuplicate"
                        :class="{'errors -input': formError}"
                        class="col-12 simple__input"
                        type="password"
                    />
                </div>
            </div>
            <div class="simple__element simple__element--buttons">
                <div class="simple__button simple__button--flex-center" @click="restore">
                    <span class="simple__text simple__text--buttons">
                        Сохранить
                    </span>
                </div>
            </div>
            <!--          FORM ERRORS BLOCK-->
            <div v-if="formError" class="simple__element simple__element--errors">
                <div v-if="formError" class="errors -text">
                    Пароли не совпадают
                </div>
            </div>
            <!--          FORM ERRORS BLOCK END-->

            <div class="simple__element simple__element--footer">
                <img src="../../img/bio.png" alt="Agro Bio Tech" width="195" />
            </div>
        </div>
    </div>
</template>

<script>
  import '../../sass/pages/simple/simple.scss';

export default {
  name: 'Restore',
  data() {
    return {
      resetToken: root_element.dataset.resetToken,
      formError: false,
      formData: {
        password: '',
        passwordDuplicate: '',
      },
    };
  },
  computed: {
    passwordDuplicateError() {
      return this.formData.password !== this.formData.passwordDuplicate;
    },
  },
  watch: {
    formData: {
      deep: true,
      handler: function () {
        this.$set(this, 'formError', false);
      },
    },
  },
  created() {
  },
  methods: {
    restore() {
      if (this.formData.password.length === 0) {
        this.$message.errorMessage('Введите пароль');
        return;
      }

      if (this.passwordDuplicateError) {
        this.$set(this, 'formError', true);
        return;
      }

      const passRestoreData = new FormData();
      passRestoreData.append('reset_token', this.resetToken);
      passRestoreData.append('password', this.formData.password);

      this.$_API.post(this.$_API_ROUTES.auth.newPass, passRestoreData)
        .then(({data}) => {
          if (data.success && data.token) {
            this.$_Auth.setAuthToken(data.token);
            this.$_API_HELPER.redirectToAdmin();
          }

          if (!data.success && data.errors) {
            this.$errors.parseErrors(data.errors);
          }
        });
    },
  },
};
</script>

<style scoped>

</style>
