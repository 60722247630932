<template>
    <div class="modal__background -simple">
        <div class="modal -simple">
            <div class="modal__element -header">
                Сбросить пароль?
            </div>
            <div class="modal__element -description">
                Письмо с ссылкой на восстановление пароля отправлено на почту {{ email }}
            </div>
            <div class="modal__element -buttons-container -single -centered">
                <v-button
                    text="Спасибо" width="174"
                    type="green"
                    @button-click="close"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Buttons from '../helpers/Buttons.vue';

export default {
  name: 'ModalPassReset',
  components: {
    'v-button': Buttons,
  },
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modalBackground: 'modal__background -simple',
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>

</style>
