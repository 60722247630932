import lodash from 'lodash';

import Vue from 'vue';
import Vuex from 'vuex';

// import '../../registerServiceWorker';

/**
 *  Import Services
 */
import ApiService from '../../configs/services/api/ApiService';
import AppConfigs from '../../configs/AppConfigs';
import AuthService from '../../configs/services/auth/AuthService';
import LangService from '../../configs/services/lang/LangService';

import '../../preloader';

/**
 *  Components import.
 */
import Login from '../../components/main/Login.vue';
import NewPass from '../../components/main/NewPass.vue';
import Restore from '../../components/main/Restore.vue';
import Registration from '../../components/main/Registration.vue';
import HasNotAccess from '../../components/main/HasNotAccess.vue';

/**
 * Store modules import.
 */
import StoreApp from '../../store';
import StoreAdmin from '../../store/admin';

/**
 * Styles import.
 */
import '../../sass/app.scss';
import Buttons from '../../components/helpers/Buttons';

/**
 * Set app services
 */
Vue.use(ApiService);
Vue.use(AuthService);
Vue.use(LangService);
Vue.use(AppConfigs);
Vue.component('v-button', Buttons);
Vue.use(lodash);

Vue.config.productionTip = false;
window.Vue = require('vue');

const SIMPLE_ROUTES = {
  '/login/': Login,
  '/registration/': Registration,
  '/renew/': NewPass,
  '/restore/': Restore,
  '/access/': HasNotAccess,
};

/**
 * Vuex store
 */
const store = new Vuex.Store({
  modules: {
    app: StoreApp,
    admin: StoreAdmin,
  },
  strict: ENV.dev,
});

new Vue({
  el: root_element,
  store,
  props: {
    resetToken: {
      type: [String],
      required: false,
      default: '',
    },
    updateToken: {
      type: [String],
      required: false,
      default: '',
    },
    email: {
      type: [String],
      required: false,
      default: '',
    },
  },
  propsData: {...root_element.dataset},
  data: {
    currentRoute: window.location.pathname,
  },
  computed: {
    ViewComponent() {
      return SIMPLE_ROUTES[this.currentRoute];
    },
  },
  render(h) {
    return h(this.ViewComponent);
  },
});
